define("discourse/plugins/discourse-singfuse/discourse/components/homepage", ["exports", "discourse/plugins/discourse-singfuse/discourse/components/block-list", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _blockList, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Homepage = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    <BlockList />
  */
  {
    "id": "ZwsoNEK4",
    "block": "[[[8,[32,0],null,null,null]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-singfuse/discourse/components/homepage.js",
    "scope": () => [_blockList.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "homepage:Homepage"));
  var _default = _exports.default = Homepage;
});