define("discourse/plugins/discourse-singfuse/discourse/components/blocks/map", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "discourse-common/lib/icon-library", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _didInsert, _iconLibrary, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable no-undef, no-console, no-shadow, guard-for-in, no-async-promise-executor */

  class Map extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "map", [_tracking.tracked]))();
    #map = (() => (dt7948.i(this, "map"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "infoWindow", [_tracking.tracked]))();
    #infoWindow = (() => (dt7948.i(this, "infoWindow"), void 0))();
    loadMap() {
      (g => {
        let h,
          a,
          k,
          p = "The Google Maps JavaScript API",
          c = "google",
          l = "importLibrary",
          q = "__ib__",
          m = document,
          b = window;
        b = b[c] || (b[c] = {});
        let d = b.maps || (b.maps = {}),
          r = new Set(),
          e = new URLSearchParams(),
          u = () => h || (h = new Promise(async (f, n) => {
            await (a = m.createElement("script"));
            e.set("libraries", [...r] + "");
            for (k in g) {
              e.set(k.replace(/[A-Z]/g, t => "_" + t[0].toLowerCase()), g[k]);
            }
            e.set("callback", c + ".maps." + q);
            a.src = `https://maps.${c}apis.com/maps/api/js?` + e;
            d[q] = f;
            a.onerror = () => h = n(Error(p + " could not load."));
            a.nonce = m.querySelector("script[nonce]")?.nonce || "";
            m.head.append(a);
          }));
        d[l] ? console.warn(p + " only loads once. Ignoring:", g) : d[l] = function (f) {
          for (var _len = arguments.length, n = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
            n[_key - 1] = arguments[_key];
          }
          return r.add(f) && u().then(() => d[l](f, ...n));
        };
      })({
        key: "AIzaSyB7vjTWS5t72NMx7pfIQpjUA1kMVmXCpl4",
        v: "weekly"
      });
      this.initMap();
    }
    static #_3 = (() => dt7948.n(this.prototype, "loadMap", [_object.action]))();
    async initMap() {
      const position = {
        lat: 1.3521,
        lng: 103.8198
      };
      // Request needed libraries.
      //@ts-ignore
      const {
        Map
      } = await google.maps.importLibrary("maps");
      this.infoWindow = new google.maps.InfoWindow();
      this.map = new Map(document.getElementById("map"), {
        zoom: 11,
        center: position,
        mapId: "e540586731645835",
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          mapTypeIds: ["roadmap", "terrain"]
        },
        streetViewControl: false
      });
      // window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      //   const newColorScheme = event.matches ? "dark" : "light";
      // });
      this.args.model.data.forEach(place => {
        this.addMarker(place);
      });
    }
    async addMarker(place) {
      const markerIcon = (0, _iconLibrary.iconHTML)(place.icon);
      const markerElement = document.createElement("div");
      markerElement.className = "marker";
      markerElement.innerHTML = markerIcon;
      const {
        AdvancedMarkerElement
      } = await google.maps.importLibrary("marker");
      const marker = new AdvancedMarkerElement({
        position: {
          lat: parseFloat(place.latitude),
          lng: parseFloat(place.longitude)
        },
        map: this.map,
        title: place.title,
        content: markerElement
      });
      marker.addListener("click", () => {
        this.infoWindow.close();
        const headerElement = document.createElement("div");
        headerElement.className = "marker-window-header";
        headerElement.innerHTML = place.title;
        this.infoWindow.setHeaderContent(headerElement);
        const contentElement = document.createElement("div");
        contentElement.className = "marker-window-content";
        contentElement.innerHTML = place.description;
        this.infoWindow.setContent(contentElement);
        this.infoWindow.open(marker.map, marker);
      });
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div id="map" {{didInsert this.loadMap}}></div>
      
    */
    {
      "id": "1I+uVyr3",
      "block": "[[[1,\"\\n    \"],[11,0],[24,1,\"map\"],[4,[32,0],[[30,0,[\"loadMap\"]]],null],[12],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-singfuse/discourse/components/blocks/map.js",
      "scope": () => [_didInsert.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Map;
});