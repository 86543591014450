define("discourse/plugins/discourse-singfuse/discourse/api-initializers/init-homepage", ["exports", "discourse/lib/api", "discourse/plugins/discourse-singfuse/discourse/components/homepage"], function (_exports, _api, _homepage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("1.15.0", api => {
    api.renderInOutlet("custom-homepage", _homepage.default);
  });
});