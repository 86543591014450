define("discourse/plugins/discourse-singfuse/discourse/discourse-singfuse-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("business", {
      resetNamespace: true
    }, function () {
      this.route("index", {
        path: "/"
      });
      this.route("directory");
      this.route("new");
      this.route("show", {
        path: "/:handle"
      });
    });
  }
});