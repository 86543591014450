define("discourse/plugins/discourse-singfuse/discourse/components/blocks/topic-list", ["exports", "@glimmer/component", "@ember/service", "discourse-common/helpers/d-icon", "discourse/plugins/discourse-singfuse/discourse/components/blocks/topic-list-item", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _dIcon, _topicListItem, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TopicList extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    get topics() {
      return this.args.model.data.topics.map(st => this.store.createRecord("topic", st));
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="heading">
          <a href={{@model.url}} class="flex">
            {{! template-lint-disable style-concatenation }}
            <h3><span style="color: {{@model.color}}">{{icon @model.icon}}</span>
              {{@model.name}}</h3>
            {{! template-lint-disable style-concatenation }}
            <span style="color: {{@model.color}}">{{icon "arrow-right"}}</span>
          </a>
        </div>
        <div class="content">
          <div>
            {{#each this.topics as |topic|}}
              <div class="topic">
                <TopicListItem @topic={{topic}} />
              </div>
            {{/each}}
          </div>
        </div>
      
    */
    {
      "id": "U+Mj+8c8",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"heading\"],[12],[1,\"\\n      \"],[10,3],[15,6,[30,1,[\"url\"]]],[14,0,\"flex\"],[12],[1,\"\\n\"],[1,\"        \"],[10,\"h3\"],[12],[10,1],[15,5,[29,[\"color: \",[30,1,[\"color\"]]]]],[12],[1,[28,[32,0],[[30,1,[\"icon\"]]],null]],[13],[1,\"\\n          \"],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"],[1,\"        \"],[10,1],[15,5,[29,[\"color: \",[30,1,[\"color\"]]]]],[12],[1,[28,[32,0],[\"arrow-right\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n      \"],[10,0],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"topics\"]]],null]],null],null,[[[1,\"          \"],[10,0],[14,0,\"topic\"],[12],[1,\"\\n            \"],[8,[32,1],null,[[\"@topic\"],[[30,2]]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@model\",\"topic\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-singfuse/discourse/components/blocks/topic-list.js",
      "scope": () => [_dIcon.default, _topicListItem.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TopicList;
});