define("discourse/plugins/discourse-singfuse/discourse/services/blocks", ["exports", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax"], function (_exports, _tracking, _object, _service, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Blocks extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "items", [_tracking.tracked], function () {
      return [];
    }))();
    #items = (() => (dt7948.i(this, "items"), void 0))();
    async get() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      (0, _ajax.ajax)("/blocks.json").then(data => {
        if (!data) {
          return;
        }
        this.items = this.parseResults(data);
      }).catch(error => {
        // eslint-disable-next-line no-console
        console.error("Error fetching blocks:", error);
      }).finally(() => {
        this.loading = false;
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "get", [_object.action]))();
    parseResults(results) {
      return results.map(topic => ({
        ...topic
      }));
    }
  }
  _exports.default = Blocks;
});