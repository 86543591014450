define("discourse/plugins/discourse-singfuse/discourse/components/blocks/topic-list-item", ["exports", "discourse/components/topic-list/topic-link", "discourse/components/topic-status", "discourse/helpers/category-link", "discourse/helpers/discourse-tags", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _topicLink, _topicStatus, _categoryLink, _discourseTags, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TopicListItem = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="top-row">
      <TopicStatus @topic={{@topic}} />
      <TopicLink @topic={{@topic}} />
    </div>
    {{#if @topic.excerpt}}
      <div class="excerpt">{{@topic.excerpt}}</div>
    {{/if}}
    <div class="bottom-row flex">
      {{categoryLink @topic.category}}
      {{discourseTags @topic mode="list"}}
    </div>
  
  */
  {
    "id": "6B2PBm5H",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"top-row\"],[12],[1,\"\\n    \"],[8,[32,0],null,[[\"@topic\"],[[30,1]]],null],[1,\"\\n    \"],[8,[32,1],null,[[\"@topic\"],[[30,1]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,1,[\"excerpt\"]],[[[1,\"    \"],[10,0],[14,0,\"excerpt\"],[12],[1,[30,1,[\"excerpt\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"bottom-row flex\"],[12],[1,\"\\n    \"],[1,[28,[32,2],[[30,1,[\"category\"]]],null]],[1,\"\\n    \"],[1,[28,[32,3],[[30,1]],[[\"mode\"],[\"list\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@topic\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-singfuse/discourse/components/blocks/topic-list-item.js",
    "scope": () => [_topicStatus.default, _topicLink.default, _categoryLink.default, _discourseTags.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "topic-list-item:TopicListItem"));
  var _default = _exports.default = TopicListItem;
});