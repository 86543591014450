define("discourse/plugins/discourse-singfuse/discourse/components/blocks/intro", ["exports", "discourse/helpers/emoji", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _emoji, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Intro = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="heading flex">
      <h3>{{emoji @model.icon}} {{@model.name}} <i>Community</i></h3>
    </div>
    <div class="content">
      <a class="btn btn-primary join" href="/signup">Create an account</a>
      <a href="/login" class="login">Sign In</a>
    </div>
  
  */
  {
    "id": "kzA+H0CA",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"heading flex\"],[12],[1,\"\\n    \"],[10,\"h3\"],[12],[1,[28,[32,0],[[30,1,[\"icon\"]]],null]],[1,\" \"],[1,[30,1,[\"name\"]]],[1,\" \"],[10,\"i\"],[12],[1,\"Community\"],[13],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n    \"],[10,3],[14,0,\"btn btn-primary join\"],[14,6,\"/signup\"],[12],[1,\"Create an account\"],[13],[1,\"\\n    \"],[10,3],[14,6,\"/login\"],[14,0,\"login\"],[12],[1,\"Sign In\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@model\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-singfuse/discourse/components/blocks/intro.js",
    "scope": () => [_emoji.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "intro:Intro"));
  var _default = _exports.default = Intro;
});