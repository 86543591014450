define("discourse/plugins/discourse-singfuse/discourse/templates/business/show", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section>
    <div class="business-profile">
      <div class="business-info">
        <h1>{{this.model.name}}</h1>
        <p>{{this.model.description}}</p>
        <div class="contact block">
          <div class="heading">
            <h3>{{d-icon "address-card"}} Contact details</h3>
          </div>
          <dl>
            <dt>{{d-icon "globe"}}</dt>
            <dd><a
                href={{this.model.website_url}}
                target="_blank"
                rel="noopener noreferrer"
              >{{this.model.website_url}}</a></dd>
            <dt>{{d-icon "envelope"}}</dt>
            <dd><a
                href="mailto://{{this.model.email}}"
                target="_blank"
                rel="noopener noreferrer"
              >{{this.model.email}}</a></dd>
            <dt>{{d-icon "mobile-alt"}}</dt>
            <dd>+65 {{this.model.phone}}</dd>
          </dl>
        </div>
      </div>
      <CorporateEntity @model={{this.model.corporate_entity}} />
    </div>
  </section>
  */
  {
    "id": "vNr8dWZu",
    "block": "[[[10,\"section\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"business-profile\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"business-info\"],[12],[1,\"\\n      \"],[10,\"h1\"],[12],[1,[30,0,[\"model\",\"name\"]]],[13],[1,\"\\n      \"],[10,2],[12],[1,[30,0,[\"model\",\"description\"]]],[13],[1,\"\\n      \"],[10,0],[14,0,\"contact block\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"heading\"],[12],[1,\"\\n          \"],[10,\"h3\"],[12],[1,[28,[35,0],[\"address-card\"],null]],[1,\" Contact details\"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"dl\"],[12],[1,\"\\n          \"],[10,\"dt\"],[12],[1,[28,[35,0],[\"globe\"],null]],[13],[1,\"\\n          \"],[10,\"dd\"],[12],[10,3],[15,6,[30,0,[\"model\",\"website_url\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,[30,0,[\"model\",\"website_url\"]]],[13],[13],[1,\"\\n          \"],[10,\"dt\"],[12],[1,[28,[35,0],[\"envelope\"],null]],[13],[1,\"\\n          \"],[10,\"dd\"],[12],[10,3],[15,6,[29,[\"mailto://\",[30,0,[\"model\",\"email\"]]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,[30,0,[\"model\",\"email\"]]],[13],[13],[1,\"\\n          \"],[10,\"dt\"],[12],[1,[28,[35,0],[\"mobile-alt\"],null]],[13],[1,\"\\n          \"],[10,\"dd\"],[12],[1,\"+65 \"],[1,[30,0,[\"model\",\"phone\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,1],null,[[\"@model\"],[[30,0,[\"model\",\"corporate_entity\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"d-icon\",\"corporate-entity\"]]",
    "moduleName": "discourse/plugins/discourse-singfuse/discourse/templates/business/show.hbs",
    "isStrictMode": false
  });
});