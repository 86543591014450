define("discourse/plugins/discourse-singfuse/discourse/controllers/business-new", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/plugins/discourse-singfuse/discourse/models/business", "discourse/plugins/discourse-singfuse/discourse/models/corporate-entity"], function (_exports, _tracking, _controller, _object, _service, _ajax, _business, _corporateEntity3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BusinessNewController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "uen", [_tracking.tracked], function () {
      return "";
    }))();
    #uen = (() => (dt7948.i(this, "uen"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "handle", [_tracking.tracked], function () {
      return "";
    }))();
    #handle = (() => (dt7948.i(this, "handle"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "validHandle", [_tracking.tracked], function () {
      return null;
    }))();
    #validHandle = (() => (dt7948.i(this, "validHandle"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "reason", [_tracking.tracked], function () {
      return "";
    }))();
    #reason = (() => (dt7948.i(this, "reason"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "corporateEntity", [_tracking.tracked], function () {
      return null;
    }))();
    #corporateEntity = (() => (dt7948.i(this, "corporateEntity"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "finding", [_tracking.tracked], function () {
      return false;
    }))();
    #finding = (() => (dt7948.i(this, "finding"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "checking", [_tracking.tracked], function () {
      return false;
    }))();
    #checking = (() => (dt7948.i(this, "checking"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "submitting", [_tracking.tracked], function () {
      return false;
    }))();
    #submitting = (() => (dt7948.i(this, "submitting"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "name", [_tracking.tracked], function () {
      return "";
    }))();
    #name = (() => (dt7948.i(this, "name"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "website", [_tracking.tracked], function () {
      return "";
    }))();
    #website = (() => (dt7948.i(this, "website"), void 0))();
    static #_12 = (() => dt7948.g(this.prototype, "email", [_tracking.tracked], function () {
      return "";
    }))();
    #email = (() => (dt7948.i(this, "email"), void 0))();
    static #_13 = (() => dt7948.g(this.prototype, "phone", [_tracking.tracked], function () {
      return "";
    }))();
    #phone = (() => (dt7948.i(this, "phone"), void 0))();
    static #_14 = (() => dt7948.g(this.prototype, "description", [_tracking.tracked], function () {
      return "";
    }))();
    #description = (() => (dt7948.i(this, "description"), void 0))();
    get invlidHandle() {
      return this.validHandle === false;
    }
    checkHandle() {
      if (this.checking) {
        return;
      }
      if (!this.handle) {
        this.validHandle = null;
        return;
      }
      return (0, _ajax.ajax)("/business/check-handle", {
        data: {
          handle: this.handle
        }
      }).then(result => {
        if (result.valid_handle) {
          this.validHandle = true;
        } else {
          this.validHandle = false;
          this.reason = result.reason;
        }
      });
    }
    static #_15 = (() => dt7948.n(this.prototype, "checkHandle", [_object.action]))();
    create() {
      if (!this.corporateEntity || this.submitting) {
        return;
      }
      this.submitting = true;
      _business.default.create({
        handle: this.handle,
        corporate_entity_id: this.corporateEntity.id,
        name: this.name,
        website: this.website,
        email: this.email,
        phone: this.phone,
        description: this.description
      }).then(data => {
        this.router.transitionTo("business.show", data.business.handle);
        this.submitting = false;
      });
    }
    static #_16 = (() => dt7948.n(this.prototype, "create", [_object.action]))();
    find() {
      this.finding = true;
      _corporateEntity3.default.find(this.uen).then(data => {
        this.corporateEntity = data.corporate_entity;
        this.finding = false;
        this.name = this.formatName(data.corporate_entity.name);
        this.handle = this.generateHandle(this.name);
      });
    }
    static #_17 = (() => dt7948.n(this.prototype, "find", [_object.action]))();
    formatName(name) {
      name = name.replace(/PTE\. LTD\.$/, "");
      name = name.replace(/PTE LTD$/, "");
      name = name.toLowerCase().split(" ").map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(" ");
      return name.trim();
    }
    generateHandle(name) {
      return name.toLowerCase().replace(/[^a-z0-9]/g, "-");
    }
  }
  _exports.default = BusinessNewController;
});