define("discourse/plugins/discourse-singfuse/discourse/components/blocks/forum", ["exports", "discourse/helpers/category-link", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _categoryLink, _dIcon, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Forum = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="heading">
      <a href={{@model.url}} class="flex">
        <h3><span>{{icon @model.icon}}</span>
          {{@model.name}}</h3>
        <span>{{icon "arrow-right"}}</span>
      </a>
    </div>
    <div class="content">
      <div class="grid category-list">
        {{#each @model.data.categories as |category|}}
          {{categoryLink category}}
        {{/each}}
      </div>
    </div>
  
  */
  {
    "id": "vSrRWclY",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"heading\"],[12],[1,\"\\n    \"],[10,3],[15,6,[30,1,[\"url\"]]],[14,0,\"flex\"],[12],[1,\"\\n      \"],[10,\"h3\"],[12],[10,1],[12],[1,[28,[32,0],[[30,1,[\"icon\"]]],null]],[13],[1,\"\\n        \"],[1,[30,1,[\"name\"]]],[13],[1,\"\\n      \"],[10,1],[12],[1,[28,[32,0],[\"arrow-right\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"grid category-list\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1,[\"data\",\"categories\"]]],null]],null],null,[[[1,\"        \"],[1,[28,[32,1],[[30,2]],null]],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@model\",\"category\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-singfuse/discourse/components/blocks/forum.js",
    "scope": () => [_dIcon.default, _categoryLink.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "forum:Forum"));
  var _default = _exports.default = Forum;
});