define("discourse/plugins/discourse-singfuse/discourse/models/business", ["exports", "@ember/object", "discourse/lib/ajax"], function (_exports, _object, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Business extends _object.default {
    static find(handle) {
      return (0, _ajax.ajax)(`/business/${handle}.json`);
    }
    static create(data) {
      return (0, _ajax.ajax)("/business", {
        type: "POST",
        data
      });
    }
    static fetchIndex() {
      return (0, _ajax.ajax)("/business.json");
    }
  }
  _exports.default = Business;
});