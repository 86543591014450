define("discourse/plugins/discourse-singfuse/discourse/components/blocks/fact", ["exports", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dIcon, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Fact = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="heading flex">
      <h3>{{icon @model.icon}} {{@model.name}}</h3>
      {{#if @model.data.source_url}}
        <a
          href={{@model.data.source_url}}
          class="source"
          target="_blank"
          rel="noopener noreferrer"
        >{{icon "external-link-alt"}}
          Source</a>
      {{/if}}
    </div>
    <div class="content">
      {{! template-lint-disable no-triple-curlies }}
      {{{@model.data.text}}}
    </div>
  
  */
  {
    "id": "hog35qfK",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"heading flex\"],[12],[1,\"\\n    \"],[10,\"h3\"],[12],[1,[28,[32,0],[[30,1,[\"icon\"]]],null]],[1,\" \"],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"],[41,[30,1,[\"data\",\"source_url\"]],[[[1,\"      \"],[10,3],[15,6,[30,1,[\"data\",\"source_url\"]]],[14,0,\"source\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,[28,[32,0],[\"external-link-alt\"],null]],[1,\"\\n        Source\"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n\"],[1,\"    \"],[2,[30,1,[\"data\",\"text\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@model\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-singfuse/discourse/components/blocks/fact.js",
    "scope": () => [_dIcon.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "fact:Fact"));
  var _default = _exports.default = Fact;
});