define("discourse/plugins/discourse-singfuse/discourse/routes/business-index", ["exports", "@ember/service", "discourse/routes/discourse", "discourse/plugins/discourse-singfuse/discourse/models/business"], function (_exports, _service, _discourse, _business) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    router: (0, _service.inject)(),
    model() {
      return _business.default.fetchIndex();
    },
    titleToken() {
      return "Singapore";
    }
  });
});