define("discourse/plugins/discourse-singfuse/discourse/templates/business/directory", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="page-title">
    <h1>{{d-icon "address-book"}} Business Directory</h1>
    <LinkTo @route="business.new" class="btn btn-primary">
      {{d-icon "plus"}}
      <span>{{i18n "singfuse.business.new"}}</span>
    </LinkTo>
  </div>
  */
  {
    "id": "pm1qfpUR",
    "block": "[[[10,0],[14,0,\"page-title\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,[28,[35,0],[\"address-book\"],null]],[1,\" Business Directory\"],[13],[1,\"\\n  \"],[8,[39,1],[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"business.new\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"plus\"],null]],[1,\"\\n    \"],[10,1],[12],[1,[28,[35,2],[\"singfuse.business.new\"],null]],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"d-icon\",\"link-to\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-singfuse/discourse/templates/business/directory.hbs",
    "isStrictMode": false
  });
});